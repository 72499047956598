{
  "search": "Search",
  "search_no_results_1": "Oh no!",
  "search_no_results_2": "That emoji couldn’t be found",
  "pick": "Pick an emoji…",
  "add_custom": "Add custom emoji",
  "categories": {
    "activity": "Activity",
    "custom": "Custom",
    "flags": "Flags",
    "foods": "Food & Drink",
    "frequent": "Frequently used",
    "nature": "Animals & Nature",
    "objects": "Objects",
    "people": "Smileys & People",
    "places": "Travel & Places",
    "search": "Search Results",
    "symbols": "Symbols"
  },
  "skins": {
    "choose": "Choose default skin tone",
    "1": "Default",
    "2": "Light",
    "3": "Medium-Light",
    "4": "Medium",
    "5": "Medium-Dark",
    "6": "Dark"
  }
}
